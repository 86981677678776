<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-08-25 14:01:05
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-14 16:16:24
 * @FilePath: /lcwpcvue/src/views/jobInfoPage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="job-inf-page">
    <div class="top-zk-div"></div>
    <div style="width: 100%; background-color: white">
      <div class="top-jobb-inf-view-s">
        <div class="left-title-gz-ll">
          <div class="title-tag-gz-view">
            <div class="job-title">{{ jobInfo.name }}</div>
            <div class="tags" v-show="jobInfo.urgent_time > 0">急聘</div>
            <div class="job-gz" v-show="jobInfo.salary_type != 1">
              {{ jobInfo.minsalary / 1000 }}-{{ jobInfo.maxsalary / 1000 }}K
            </div>
            <div class="job-gz" v-show="jobInfo.salary_type == 1">面议</div>
          </div>
          <div class="add-exp-edu-view">
            <div class="items">
              <!-- <i class="el-icon-location-outline"></i> -->
              <img src="../assets/jobinf_addr_img.png" alt="" />
              <span>{{ jobInfo.province_name }}-{{ jobInfo.pcity_name }}</span>
            </div>
            <div class="items">
              <img src="../assets/jobinf_gzjy_img.png" alt="" />
              <span>{{ jobInfo.exp_name }}</span>
            </div>
            <div class="items">
              <img src="../assets/jobinf_jyjl_img.png" alt="" />
              <span>{{ jobInfo.edu_name }}</span>
            </div>
          </div>
          <div class="lab-view">
            <label v-for="(item, index) in jobInfo.welfare" :key="index">
              <span class="lab-item">{{ item }}</span>
            </label>
          </div>
          <div class="up-look-jb-view">
            <div class="items">
              <img src="../assets/jobinf_uptime_img.png" alt="" />
              <span>更新于 {{ jobInfo.time }}</span>
            </div>
            <div class="items">
              <img src="../assets/jobinf_look_img.png" alt="" />
              <span>浏览 {{ jobInfo.jobhits }}次</span>
            </div>
            <div class="items" style="cursor: pointer" @click="toReport">
              <img src="../assets/jobinf_jb_img.png" alt="" />
              <span>举报</span>
            </div>
          </div>
        </div>
        <div class="btn-share-view" v-show="!isYl">
          <div class="btn-view">
            <el-button class="btn1" @click="toChatPage">
              <i class="el-icon-s-comment"></i>聊一聊
            </el-button>
            <el-button
              v-show="isApply === '0'"
              @click="toDeliverResumeAfter"
              type="primary"
              class="btn2"
              >投简历</el-button
            >
            <el-button
              v-show="isApply === '1'"
              type="info"
              plain
              disabled
              class="btn2"
              >已申请</el-button
            >
          </div>
          <div class="shar-clo-view">
            <div
              class="items"
              @click="getXCXQrcodeImg"
              style="position: relative"
            >
              <img src="../assets/jobinf_share_img.png" alt="" />
              <span>分享</span>
              <div id="qrcode-image-divl" v-show="isShowQrcodeImg && qrcodeImg">
                <div class="triangle-job"></div>
                <div class="qrcode-image-job">
                  <img :src="qrcodeImg" style="width: 100%; height: 100%" />
                </div>
              </div>
            </div>
            <!--          <div class="items">
            <img src="../assets/jobinf_clo_img.png" alt="" />
            <span>收藏</span>
          </div>-->
            <div class="items" @click="postCollection">
              <span v-show="isColl === '1'">
                <i class="el-icon-star-on" style="font-size: 18px"></i
                ><span style="vertical-align: top">已收藏</span>
              </span>
              <span v-show="isColl === '0'">
                <i class="el-icon-star-off" style="font-size: 14px"></i
                ><span style="vertical-align: top">收藏</span>
              </span>
            </div>
          </div>
        </div>

        <!-- 3开放中  4待开放   5审核不通过    6已关闭 -->
        <div class="preview-btn-view" v-show="isYl">
          <el-button
            v-if="
              previewStatu == 3 ||
              previewStatu == 4 ||
              previewStatu == 5 ||
              previewStatu == 6
            "
            type="primary"
            @click="toEdtJobInf"
            plain
            >编辑</el-button
          >
          <el-button v-if="previewStatu == 3" @click="closeJob">关闭</el-button>
          <el-button
            v-if="previewStatu == 4 || previewStatu == 5 || previewStatu == 6"
            @click="delJob"
            >删除</el-button
          >
          <el-button
            v-if="previewStatu == 4 || previewStatu == 6"
            type="primary"
            @click="onFullJobInMarange"
            >开放职位</el-button
          >

          <!-- <div class="shar-clo-view">
            <div class="items" @click="delJob">
              <img src="@/assets/user_resume_del.png" alt="" />
              <span>删除</span>
            </div>
            <div class="items" @click="closeJob">
              <img src="@/assets/yl_job_colse_job.png" alt="" />
              <span>关闭</span>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="min-view">
      <div class="">
        <div class="com-sty zw-detail-view">
          <div class="title-one">职位详情</div>
          <div
            class="work-titme-view"
            v-show="jobInfo.work_time || jobInfo.work_shift_name"
          >
            工作时间：
            <span v-show="jobInfo.work_time">
              <img :src="require('@/assets/company_tiem-img.png')" alt="" />
              <span>{{ jobInfo.work_time }}</span>
            </span>
            <span v-show="jobInfo.work_shift_name" style="margin-left: 15px">
              <img :src="require('@/assets/company_rl_img.png')" alt="" />
              <span>{{ jobInfo.work_shift_name }}</span> /
              <span>{{ jobInfo.rest_day_name }}</span> /
              <span>{{ jobInfo.overtime_state_name }}</span>
            </span>
          </div>
          <div class="job-ms-view">
            <div class="ms-title">职位描述：</div>
            <div class="ms-tetail">
              {{ jobInfo.description }}
            </div>
          </div>
          <div class="lin-inf-view">
            <img class="lin-user-head-img" :src="jobInfo.logo" alt="" />
            <div class="lin-inf-text-view">
              <div class="top" style="padding: 0">
                <span class="nikname">{{ jobLinkInf.name }}</span>
                <span class="dian-view"></span>
                <sapn class="status">{{ jobLinkInf.online }}</sapn>
              </div>
              <div class="lin-ze-view">
                {{ jobLinkInf.job }}·{{ jobInfo.com_name }}
              </div>
            </div>
            <div class="right-lil-btn" type="primary" @click="toChatPage">
              立即沟通
            </div>
          </div>
        </div>
        <div class="com-sty com-addr">
          <div class="title-one">工作地点</div>
          <div class="addr-view">
            <img src="../assets/jobinf_addr_img.png" alt="" />
            <span>{{ jobInfo.address }}</span>
          </div>
          <div class="map">
            <zczmap
              v-if="jobInfo.x"
              :lat="jobInfo.y"
              :lng="jobInfo.x"
              idname="jobmap"
              :mapwidth="'780'"
              :mapheight="'335'"
            ></zczmap>
          </div>
        </div>
        <div class="com-sty min-ju-view">
          <div class="lc-ts-view">
            <img src="../assets/jobinf_minjb_img.png" alt="" />
            <span>猎宠网安全提示：</span>
          </div>
          <div class="jb-text">
            求职中如遇到招聘方存在虚假信息，收取费用，扣押证件，或其他违法情形，请立即&nbsp;&nbsp;<a
              style="cursor: pointer"
              @click="toReport"
              >举报！</a
            >
          </div>
        </div>
        <div class="com-sty job-card-list-view" v-show="recJobList.length > 0">
          <div class="title-one">推荐职位</div>
          <label v-for="(item, index) in recJobList" :key="index">
            <jobinfcardinjobinf
              class="inucad"
              :jobItem="item"
              @updateJobDetail="openNewjobByemit"
            ></jobinfcardinjobinf>
            <div v-show="(index + 1) % 3 != 0" class="card-fgx"></div>
          </label>
        </div>
      </div>
      <div class="">
        <companyinjobinf
          class="com-inf-car"
          :companyInf="jobInfo"
          :imgurls="jobShowImg"
        ></companyinjobinf>

        <div class="jobdetail-tjjob-view">
          <div class="tj-title-view">
            <span class="tj-view">该公司其TA职位</span>
          </div>
          <div class="tj-list">
            <label v-for="(item, idx) in comIdJobList" :key="idx">
              <div class="tjitem" @click.stop="openNewjob(item.id)">
                <div class="tj-top-view">
                  <span class="tj-t1">
                    <span class="tj-job-name">{{ item.name }}</span>
                    <span v-show="item.part === '1'" class="jz-tag-inco"
                      >兼职</span
                    ></span
                  >
                  <span v-show="item.part !== '1'">
                    <span class="spar-gz" v-show="item.minsalary !== '0'"
                      >￥{{ item.minsalary }}-{{ item.maxsalary }}</span
                    >
                    <span class="spar-gz" v-show="item.minsalary === '0'"
                      >面议</span
                    >
                  </span>
                  <span v-show="item.part === '1'">
                    <span class="spar-gz">{{ item.salary }}</span>
                  </span>
                </div>
                <div class="com-name">
                  {{ item.com_name }}
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <reportdialog
      :reportDialogShow="reportDialogShow"
      :dlTitle="'举报职位'"
      :rpUid="jobid"
      :cuid="jobInfo.uid"
      @closeReportDialog="closeReportDialog"
    ></reportdialog>
    <ComDialog
      :isopen="isOpenDeliverChoose"
      dialogTetle="申请职位"
      @dialogFrameEvent="dialogFrameEvent"
      myWidth="500px"
      :isShowButt="true"
    >
      <div class="apply-job">
        <p>职位名称：{{ jobInfo.name }}</p>
        <p style="margin: 20px 0">公司名称：{{ jobInfo.com_name }}</p>
        <p>
          <span>是否显示推荐：</span>
          <el-radio-group v-model="isShowRec">
            <el-radio label="1">显示平台推荐</el-radio>
            <el-radio label="2">不显示</el-radio>
          </el-radio-group>
        </p>
      </div>
    </ComDialog>

    <PayJobDia
      :isopen="isOpenPayJob"
      :params="payJobParams"
      @payJobChang="payJobChang"
    ></PayJobDia>
  </div>
</template>

<script>
import jobinfcardinjobinf from "@/components/comUser/jobInfCardInJobInf.vue";
import companyinjobinf from "@/components/individualUser/companyInJobInf.vue";
import {
  getJobDetails,
  getRecommendJobList,
  getJobListByComId,
  collectionCompany,
  deliverResume,
  checkUserIsCanChat,
} from "@/api/indiviuser/jobInfoPage";
import { checkIsLahe } from "@/api/commen/aboutTalentsApi.js";
import {
  colseMyFullJob,
  delMyFullJob,
  onFullJobInMarange, //审核上架全职职位
  fullTimeUpAgain, //直接上架全职职位
} from "@/api/compe/jobsApi.js";
// companyInJobInf
import reportdialog from "@/components/reportDialog.vue";
import { userPcApi } from "@/api/commen/transferShop";
import ComDialog from "@/components/comDialog.vue";
import PayJobDia from "@/components/jobClass/payJob.vue";
export default {
  name: "jobinf",
  components: {
    ComDialog,
    jobinfcardinjobinf,
    companyinjobinf,
    reportdialog,
    PayJobDia,
  },
  data() {
    return {
      jobid: "",
      jobInfo: {},
      jobLinkInf: {},
      comIdJobList: {},
      isYl: false,
      isPartJob: 0,
      reportDialogShow: false,
      recJobList: [],
      isColl: "0",
      isApply: "0",
      qrcodeImg: "",
      isShowQrcodeImg: false,
      jobShowImg: "",
      isAd: "0",
      isOpenDeliverChoose: false,
      isShowRec: "1",
      previewStatu: "",
      payJobParams: {},
      isOpenPayJob: false,
      auditStatus: null,

    };
  },
  created() {
    this.jobid = this.$route.query.id;
    if (this.$route.query.isyl) {
      this.isYl = true;
      this.previewStatu = this.$route.query.isyl;
      if (this.$route.query.state) {
        this.auditStatus = this.$route.query.state;
      }
    }

    //3开放中  4待开放   5审核不通过    6已关闭
    console.log("预览类型", this.previewStatu);
  },
  mounted() {
    this.getJobDetails();
    this.getRecJobList();
  },

  methods: {
    openNewjobByemit(id) {
      this.jobid = id;
      this.getJobDetails();
      window.scrollTo(0, 0);
    },
    openNewjob(id) {
      this.jobid = id;
      this.getJobDetails();
      window.scrollTo(0, 0);
    },
    toChatPage() {
      if (!localStorage.getItem("token")) {
        this.$store.dispatch("user/setUserType", 1);
        this.$cookies.set("usertype", 1);
        localStorage.setItem("usertype", 1);
        this.$router.push("/login");
        return;
      }
      checkUserIsCanChat()
        .then((result) => {
          console.log("ooopppooo", result);
          if (result.data.score > result.data.myscore || result.code == 102) {
            // 102简历未通过审核
            this.$message({
              message: result.msg,
              type: "warning",
            });
            // uni.showToast({
            //   title: res.msg,
            //   icon: "none",
            // });
            setTimeout(() => {
              this.$router.push(`/useredtresume`);
            }, 1500);
          } else {
            checkIsLahe({ to_uid: this.jobInfo.uid })
              .then((result) => {
                console.log("是否拉黑", result);
                if (result.data.hmd == 1) {
                  this.$confirm(
                    "您已将对方拉黑，确认要给他发送消息吗？",
                    "提示",
                    {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    }
                  )
                    .then(() => {
                      this.$router.push(`/chatpage?chatid=${this.jobInfo.uid}`);
                    })
                    .catch(() => {});
                } else {
                  if (result.data.to_hmd == 1) {
                    this.$confirm(
                      "对方已将你拉黑，您不能发送消息给TA!",
                      "提示",
                      {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                      }
                    )
                      .then(() => {})
                      .catch(() => {});
                  } else {
                    this.$router.push(`/chatpage?chatid=${this.jobInfo.uid}`);
                  }
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});

      //
    },
    dialogFrameEvent(e) {
      if (e === "ok") {
        this.toDeliverResume();
      }
      this.isOpenDeliverChoose = false;
    },
    toDeliverResumeAfter() {
      if (this.isAd === "1") {
        this.isOpenDeliverChoose = true;
      } else {
        this.toDeliverResume();
      }
    },
    toDeliverResume() {
      let isShow = "0";
      if (this.isAd === "1") {
        isShow = this.isShowRec;
      }
      if (!localStorage.getItem("token")) {
        this.$store.dispatch("user/setUserType", 1);
        this.$cookies.set("usertype", 1);
        localStorage.setItem("usertype", 1);
        this.$router.push("/login");
        return;
      }
      deliverResume(this.jobid, this.jobInfo.uid, isShow).then((res) => {
        if (res.code == 102) {
          // 简历分太低,跳转完善简历
          this.$message({
            message: res.msg,
            type: "warning",
          });
          setTimeout(() => {
            this.$router.push("/useredtresume");

            // uni.navigateTo({
            //   url: "/pagesA/user/resume/resume",
            // });
          }, 1500);

          return;
        } else if (res.msg == "您的简历还未审核，审核后再来申请吧！") {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        } else if (
          res.msg == "很抱歉，您的简历信息未通过审核，请先去修改简历吧！"
        ) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          setTimeout(() => {
            this.$router.push("/useredtresume");
          }, 1500);
        } else if (res.msg == "申请成功") {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.isApply = this.isApply === "0" ? "1" : "0";
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          this.getJobDetails();
          // this.datas.is_apply = 1;
        }
      });
    },
    //获取小程序二维码
    getXCXQrcodeImg() {
      if (!this.qrcodeImg) {
        userPcApi({
          type: "createQrcode",
          id: this.jobid,
          exeType: "job",
        }).then((res) => {
          if (res.code === 200) {
            this.qrcodeImg = res.data.img;
          }
        });
      }
      this.isShowQrcodeImg = !this.isShowQrcodeImg;
    },
    /* 收藏 */
    postCollection() {
      if (!localStorage.getItem("token")) {
        this.$store.dispatch("user/setUserType", 1);
        this.$cookies.set("usertype", 1);
        localStorage.setItem("usertype", 1);
        this.$router.push("/login");
        return;
      }
      if (!this.jobid) return;
      collectionCompany({ job_id: this.jobid }).then((res) => {
        this.isColl = this.isColl === "1" ? "0" : "1";
        this.$message.success(res.msg);
      });
    },
    getRecJobList() {
      if (!localStorage.getItem("token")) return;
      getRecommendJobList({ id: this.jobid }).then((res) => {
        console.log("getRecommendJobList");
        console.log(res);
        if (res.data.length > 0) {
          this.recJobList = res.data;
          sessionStorage.setItem(
            "getRecommendJobList",
            JSON.stringify(res.data)
          );
        }
      });
    },
    //获取职位信息详情
    getJobDetails() {
      getJobDetails({ job_id: this.jobid, is_part: this.isPartJob })
        .then((result) => {
          document.title = result.data.job_info.name;
          console.log("职位信息", result);
          result.data.job_info.x = parseFloat(result.data.job_info.x);
          result.data.job_info.y = parseFloat(result.data.job_info.y);
          var jobinf = result.data.job_info;
          this.jobInfo = jobinf;
          this.isAd = result.data.is_ad;
          this.center = [jobinf.x, jobinf.y];
          this.jobLinkInf = result.data.recruit;
          this.isColl = result.data.is_coll;
          this.isApply = result.data.is_apply;
          this.getJobListByComId();
          var imgs = [];
          result.data.job_show.forEach((element) => {
            if (element.type == "img") {
              imgs.push(element.picurl);
            }
          });
          this.jobShowImg = imgs;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获取公司发布的其他职位
    getJobListByComId() {
      getJobListByComId({ company_uid: this.jobInfo.uid })
        .then((result) => {
          console.log("获取公司其他职位", result);
          this.comIdJobList = result.data.company_jobs;
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    toEdtJobInf() {
      this.$router.push(
        `/releasejob?id=${this.jobid}&ispart=${this.isPartJob}`
      );
    },

    delJob() {
      this.$confirm("确定要删除该职位吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delMyFullJob({ id: this.jobid })
            .then((result) => {
              console.log("删除职位", result);
              if (result.code == 200) {
                this.$message({
                  message: result.msg,
                  type: "success",
                });
                this.$router.go(-1);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    closeJob() {
      this.$confirm("确定要关闭该职位吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          colseMyFullJob({ id: this.jobid })
            .then((result) => {
              console.log("关闭职位", result);
              if (result.code == 200) {
                this.$message({
                  message: result.msg,
                  type: "success",
                });
                this.$router.go(-1);
              }
            })
            .catch(() => {});
        })
        .catch(() => {});
    },

    /* 重新上架 */
    onFullJobInMarange() {
      this.$confirm("确定要开放该职位吗？", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.auditStatus == 0 || this.auditStatus == 3) {
            onFullJobInMarange({ id: this.jobid })
              .then((result) => {
                console.log("重新上架", result.data.isPay);
                // if (result.code == 200) {
                //
                // }

                if (result.data.isPay) {
                  this.payJobDia(result.data.isPay, this.jobid, "no"); //jobType为no表示前端拿不到，后端去拿
                } else {
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                }
                // this.getMyJobManageList(this.navbarId);
                // this.getMyJobManageNube();
              })
              .catch(() => {});
          } else if (this.auditStatus == 1) {
            fullTimeUpAgain({ id: this.jobid })
              .then((result) => {
                if (result.data.isPay) {
                  this.payJobDia(result.data.isPay, this.jobid, "no"); //jobType为no表示前端拿不到，后端去拿
                } else {
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },

    payJobDia(params, jobId, jobType) {
      console.log('弹窗',params,jobId,jobType);
      
      params.job_id = jobId;
      params.job_type = jobType;
      this.payJobParams = params;
      this.isOpenPayJob = true;
    },
    payJobChang() {
      this.isOpenPayJob = false;
      this.$router.go(-1);
    },
    toReport() {
      console.log("举报职位");
      this.reportDialogShow = true;
    },

    closeReportDialog() {
      this.reportDialogShow = false;
    },
  },
};
</script>

<style>
.job-inf-page {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.job-inf-page .jz-tag-inco {
  width: 30px;
  height: 15px;
  font-size: 12px;
  border-radius: 5px;
  line-height: 15px;
  text-align: center;
  margin-left: 10px;
  color: #54eaab;
  border: 1px solid #54eaab;
}
.min-view {
  display: flex;
}

.job-inf-page .com-sty {
  background-color: #fff;
  margin-top: 40px;
  width: 962px;
  border-radius: 16px;
  padding: 36px;
}

.job-inf-page .com-sty .title-one {
  font-size: 32px;

  font-weight: 600;
  color: #191919;
  line-height: 38px;
}

.top-jobb-inf-view-s {
  width: 1480px;
  display: flex;
  padding: 40px 0;
  justify-content: space-between;
  margin: 0 auto;
}
.top-jobb-inf-view-s .left-title-gz-ll {
  /*margin-left: 220px;*/
}
.top-jobb-inf-view-s .left-title-gz-ll .title-tag-gz-view {
  display: flex;
  align-items: center;
}
.top-jobb-inf-view-s .left-title-gz-ll .title-tag-gz-view .job-title {
  font-size: 36px;

  font-weight: 600;
  color: #191919;
  line-height: 42px;
  max-width: 800px;
}
.top-jobb-inf-view-s .left-title-gz-ll .title-tag-gz-view .tags {
  width: 35px;
  height: 19px;
  background-color: #ffd9c3;
  color: #f34b37;
  font-size: 12px;

  font-weight: 400;
  text-align: center;
  line-height: 19px;
  border-radius: 2px;
  margin-left: 12px;
}
.top-jobb-inf-view-s .left-title-gz-ll .title-tag-gz-view .job-gz {
  font-size: 36px;

  font-weight: 600;
  color: #fe6002;
  line-height: 42px;
  margin-left: 40px;
}

.top-jobb-inf-view-s .left-title-gz-ll .add-exp-edu-view {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.top-jobb-inf-view-s .left-title-gz-ll .add-exp-edu-view .items {
  display: flex;
  align-items: center;
  margin-right: 24px;
}
.top-jobb-inf-view-s .left-title-gz-ll .add-exp-edu-view .items img {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}
.top-jobb-inf-view-s .left-title-gz-ll .add-exp-edu-view .items span {
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  line-height: 24px;
}

.top-jobb-inf-view-s .left-title-gz-ll .lab-view {
  margin-top: 24px;
}

.top-jobb-inf-view-s .left-title-gz-ll .lab-view .lab-item {
  display: inline-block;
  background-color: #f5f6fa;
  padding: 6px 16px;
  font-size: 18px;

  font-weight: 400;
  color: #757575;
  line-height: 24px;
  margin-right: 14px;
  margin-bottom: 5px;
  border-radius: 8px;
}
.top-jobb-inf-view-s .left-title-gz-ll .up-look-jb-view {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.top-jobb-inf-view-s .left-title-gz-ll .up-look-jb-view .items {
  display: flex;
  align-items: center;
  margin-right: 36px;
}
.top-jobb-inf-view-s .left-title-gz-ll .up-look-jb-view .items img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.top-jobb-inf-view-s .left-title-gz-ll .up-look-jb-view .items span {
  font-size: 14px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 16px;
}

.top-jobb-inf-view-s .btn-share-view {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  /*margin-right: 220px;*/
}
.top-jobb-inf-view-s .btn-share-view .btn-view {
  display: flex;
  align-items: center;
}
.top-jobb-inf-view-s .btn-share-view .btn1 {
  font-size: 24px;
  font-weight: 600;
  color: #fe6002;
  width: 188px;
  height: 62px;
  border: 1px solid #fe6002;
  border-radius: 4px;
}

.top-jobb-inf-view-s .btn-share-view .btn1 img {
  width: 32px;
  height: 32px;
}
.top-jobb-inf-view-s .btn-share-view .btn1 span {
}

.top-jobb-inf-view-s .btn-share-view .btn2 {
  width: 340px;
  height: 62px;
  font-size: 24px;

  font-weight: 500;
  color: #ffffff;
  line-height: 28px;
}

.top-jobb-inf-view-s .btn-share-view .shar-clo-view {
  display: flex;
  align-items: center;
  margin-top: 40px;
}
.top-jobb-inf-view-s .btn-share-view .shar-clo-view .items {
  display: flex;
  align-items: center;
  font-size: 16px;

  font-weight: 400;
  color: #fe6002;
  line-height: 19px;
  margin-left: 50px;
  cursor: pointer;
}

.top-jobb-inf-view-s .btn-share-view .shar-clo-view .items img {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.zw-detail-view .work-titme-view {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  margin-top: 40px;
}
.job-inf-page .zw-detail-view .work-titme-view span {
  font-size: 18px;
  font-weight: 400;
  color: #191919;
  line-height: 28px;
  /* margin-right: 15px; */
}
.job-inf-page .zw-detail-view .work-titme-view img {
  margin-right: 8px;
  width: 20px;
  height: auto;
  vertical-align: middle;
}
.zw-detail-view .job-ms-view {
  margin-top: 30px;
}
.zw-detail-view .job-ms-view .ms-title {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}
.zw-detail-view .job-ms-view .ms-tetail {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 30px;
  margin-top: 24px;
  white-space: pre-wrap;
}

.job-inf-page .zw-detail-view .lin-inf-view {
  display: flex;
  align-items: center;
  border-top: 2px solid #eaecf5;
  padding-top: 40px;
  margin-top: 44px;
  position: relative;
}
.zw-detail-view .lin-inf-view .lin-user-head-img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.zw-detail-view .lin-inf-view .lin-inf-text-view {
  margin-left: 18px;
}

.zw-detail-view .lin-inf-view .lin-inf-text-view .top {
  display: flex;
  align-items: center;
}

.zw-detail-view .lin-inf-view .lin-inf-text-view .top .nikname {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
}

.zw-detail-view .lin-inf-view .lin-inf-text-view .top .dian-view {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fe6002;
  margin-left: 12px;
}

.zw-detail-view .lin-inf-view .lin-inf-text-view .top .status {
  font-size: 14px;

  font-weight: 400;
  color: #757575;
  line-height: 16px;
  margin-left: 2px;
}
.zw-detail-view .lin-inf-view .lin-inf-text-view .lin-ze-view {
  font-size: 16px;
  font-weight: 400;
  color: #191919;
  line-height: 19px;
  margin-top: 16px;
}
.zw-detail-view .lin-inf-view .right-lil-btn {
  width: 100px;
  height: 40px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  position: absolute;
  right: 0;
  background-color: #fe6002;
  border-radius: 4px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
}

.com-addr .addr-view {
  display: flex;
  align-items: center;
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-top: 24px;
}
.com-addr .addr-view img {
  width: 20px;
  height: 20px;
}
.com-addr .map {
  /* width: 986px;
  height: 213px; */
  margin-top: 24px;
}
.job-inf-page .com-addr .map .amap-box {
  width: 966px;
  height: 213px;
}

.min-ju-view .lc-ts-view {
  font-size: 20px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
  display: flex;
  align-items: center;
}
.min-ju-view .lc-ts-view img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.min-ju-view .jb-text {
  font-size: 18px;

  font-weight: 400;
  color: #fe6002;
  margin-top: 10px;
}
.min-ju-view .jb-text a {
  color: #0294fe;
}
.job-card-list-view .inucad {
  border: 1px solid#E5E5E5;
}
.job-card-list-view .card-fgx {
  display: inline-block;
  width: 20px;
}
.com-inf-car {
  margin-top: 40px;
  margin-left: 43px;
}

.job-inf-page .jobdetail-tjjob-view {
  margin-top: 40px;
  margin-left: 43px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 40px;
  padding: 25px 24px;
}

.job-inf-page .jobdetail-tjjob-view .tj-view {
  font-size: 22px;

  font-weight: 600;
  color: #191919;
  line-height: 28px;
}

.job-inf-page .jobdetail-tjjob-view .tj-list .tjitem {
  border-bottom: 1px solid #f5f6fa;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 5px;
}
.job-inf-page .jobdetail-tjjob-view .tj-list .tjitem:hover {
  background-color: #fff5f0;
}
.job-inf-page .jobdetail-tjjob-view .tj-list .tjitem .tj-top-view {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.job-inf-page .jobdetail-tjjob-view .tj-list .tjitem .tj-top-view .tj-t1 {
  font-size: 18px;

  font-weight: 600;
  color: #191919;
  /*line-height: 28px;*/
}

.job-inf-page
  .jobdetail-tjjob-view
  .tj-list
  .tjitem
  .tj-top-view
  .tj-t1
  .tj-job-name {
  display: inline-block;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.job-inf-page .jobdetail-tjjob-view .tj-list .tjitem .tj-top-view .spar-gz {
  font-size: 16px;

  font-weight: 500;
  color: #fe6002;
  line-height: 19px;
}

.job-inf-page .jobdetail-tjjob-view .tj-list .tjitem .com-name {
  font-size: 12px;

  font-weight: 400;
  color: #757575;
  line-height: 14px;
  margin-top: 8px;
}
.job-inf-page .triangle-job {
  width: 20px;
  height: 20px;
  background-color: white;
  transform: rotate(45deg);
  position: absolute;
  left: 15px;
  top: 25px;
  border: 1px solid #939597;
}
.job-inf-page .qrcode-image-job {
  height: 265px;
  width: 265px;
  background-color: white;
  position: absolute;
  top: 35px;
  left: -100px;
  border: 1px solid #d9d9d9;
}
.job-inf-page .apply-job {
  padding: 20px 30px 0;
}
.job-inf-page .apply-job p {
  color: #191919;
  font-size: 16px;
}
.preview-btn-view {
  margin-top: 30px;
}
.preview-btn-view .el-button {
  width: 130px;
  font-size: 20px;
  font-weight: 600;
}
</style>