<template>
  <div class="pay-job-dia">
    <div class="pay-job-remind">
      <el-dialog
          :visible="isopen"
          width="500px"
          :show-close="false"
      >
<!--        @close="closeRemindDia"-->
        <div class="pay-job-font-div">
          <div class="pay-job-recommend">
            推荐
          </div>
          <div style="display: flex;justify-content: center;align-items: center;margin-bottom: 30px">
            <div style="border-radius:50%;width: 24px;height: 24px;background-color: #fa6a31;font-size: 16px;color: white;line-height: 24px;margin-right: 5px">￥</div>
            <div class="payjob-title">付费发布</div>
          </div>
          <p class="payjob-content">
            每天可聊：<span style="color: #FE6002;font-weight: bold">{{params.chat_num}}</span>人&nbsp;&nbsp;&nbsp;
            有效期：<span style="color: #FE6002;font-weight: bold">{{params.deadline}}</span>天
          </p>
          <p class="payjob-content">
            优惠价：<span style="color: #FE6002;font-weight: bold;font-size: 23px">{{params.price}}</span>元
          </p>
          <div class="payjob-but">
            <el-button @click.stop="closeRemindDia(false)">继续免费发布</el-button>
            <el-button type="primary" @click.stop="closeRemindDia(true)">立即开通</el-button>
          </div>
          <!--        <div style="width: 350px;margin: 0 auto">
                    <p style="text-align: left;font-size: 16px">购买说明：</p><br>
                    <p style="text-align: left;line-height: 20px;font-size: 12px">
                      1、开放职位获得直聊权益，招聘者可以主动给求职者发起聊天。<br>
                      2、猎宠网会对您发布的职位进行审核，请留意审核结果。职位未通过审核的，您支付的费用会在72小时内原路退回至您的账户。<br>
                      3、职位最终价格根据购买档位、优惠幅度、地区、职类等因素产生波动，具体金额以购买前平台显示为准。<br>
                      4、该产品属于数字化产品，不支持七天无理由退款。<br>
                      5、开放职位购买后立即生效，职位中包含的权益仅限在当前职位使用，有效期每月按30个自然日计算。<br>
                      6、升级VIP套餐更划算，详询:021-60409917，也可直接咨询在线客服<br>
                    </p>
                  </div>-->
        </div>
      </el-dialog>
    </div>

    <el-dialog
        width="400px"
        title="开放职位"
        :visible.sync="isOpenPayDia">
      <div class="pay-inf-view">
        <div class="pay-type-view">
          <div class="paytype-title">支付方式</div>
          <div class="paytype">
            <div
                class="paytype-item"
                @click="changePayType('wxpay')"
                :class="payType == 'wxpay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_wx_img.png" alt="" />
              <span>微信支付</span>
            </div>
            <div
                class="paytype-item"
                @click="changePayType('alipay')"
                :class="payType == 'alipay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
              <span>支付宝支付</span>
            </div>
          </div>
        </div>
        <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
          <div class="wxpay-tt1">
            请使用<label>微信</label>扫描二维码完成支付
          </div>
<!--          <div class="wxpay-tt2">
            如无法支付或二维码已过期请点击<label @click="refrWxPayEwm"
          >刷新</label
          >
          </div>-->
          <img class="wxpay-ewm-img" :src="wxPayImgUrl" alt="" />
          <div class="wxpay-tt2" @click="openAgreementFun">
            购买付费即表示同意<label>《猎宠网VIP服务协议》</label>
          </div>
        </div>
        <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
          <el-button type="primary" @click="toAliPayPage">去支付</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
        width="800px"
        title="开放职位"
        :visible.sync="isOpenAgreement"
        @close="closeAgreement"
    >
      <div v-html="vipServe" class="vipServe-content"></div>
    </el-dialog>
  </div>
</template>

<script>

import {getUserProfile} from "@/api/commen/login";
import {submitOrder} from "@/api/commen/payApi";
import RequestByNotLoading from "@/utils/requestByNotLoading";

export default {
  name: "payJobDia",
  components: {},
  props: ['isopen','params','jobType','jobId'],
  data() {
    return {
      isOpenDia:false,
      isOpenPayDia:false,
      wxPayImgUrl: "",
      payType: "wxpay",
      aliNatoUrl:'',
      vipServe:"",
      isOpenAgreement: false,
      setIntervalObj:'',

    };
  },
  mounted() {

  },
  beforeDestroy() {
    clearInterval(this.setIntervalObj);
  },
  methods: {
    createOrder(){
      let payobj = {
        pay_type: this.payType,
        type: 32,
        job_id: this.params.job_id,
        rating_id: "0",
        job_type: this.params.job_type,
      };
      submitOrder(payobj).then(e=>{
        if (this.payType === 'wxpay'){
          this.wxPayImgUrl = e.data.pay_img;
        }else {
          this.aliNatoUrl = e.data.url;
        }
        this.setIntervalObj = setInterval( () => {
          RequestByNotLoading({
            url: 'v2/Rating/orderStatus',
            method: 'post',
            data: {
              orderId:e.data.order_id
            },
          }).then(e=>{
            if (e.msg === '查询成功' && e.data.is_success === '2'){
              clearInterval(this.setIntervalObj);
              this.$message.success('支付成功');
              // this.$router.push('/jobmanage');
              this.isOpenPayDia = false;
              this.$emit('payJobChang',true,)
            }
          });
        },2000);
      });
    },
    openAgreementFun() {
      console.log("打开vip协议");
      if (!this.vipServe) {
        getUserProfile("/vipServe").then((res) => {
          this.vipServe = res.data;
          this.isOpenAgreement = true;
        });
      } else {
        this.isOpenAgreement = true;
      }
    },
    toAliPayPage() {
      window.open(this.aliNatoUrl, "_blank");
    },
    changePayType(type) {
      this.payType = type;
      this.createOrder();
    },
    // 关闭协议弹窗
    closeAgreement(){
      console.log('关闭协议弹窗')
      this.isOpenAgreement = false;
    },
    // 关闭支付弹窗
    closePayDia(){
      console.log('关闭支付弹窗')
      this.isOpenPayDia = false;
    },
    // 关闭发布时的付费提醒弹窗
    closeRemindDia(e){
      console.log('关闭发布时的付费提醒弹窗')
      if (e){
        this.createOrder();
        this.isOpenPayDia = true;
      }else {
        clearInterval(this.setIntervalObj);
        this.$emit('payJobChang',e,1)
      }
    }
  }
};
</script>
<style scoped>
/*>>>.el-dialog__wrapper .el-dialog {
  border-radius: 15px;
}
>>>.el-dialog .el-dialog__body{
  border-radius: 15px;
}*/
.payjob-title{
  font-size: 30px;
  color: #3b3b3b;
  font-weight: bold;
}
.payjob-content{
  font-size: 20px;
  color: #3b3b3b;
  margin-bottom: 20px;
}
>>> .payjob-but button.el-button.el-button--primary{
  width: 160px;
  height: 45px;
  line-height: 22px;
  font-size: 18px;
  margin-left: 20px;
  background-color: #533f1f;
  border: none;
  color: #e1caa5;
}
>>> .payjob-but button.el-button.el-button--default {
  width: 160px;
  height: 45px;
  line-height: 22px;
  font-size: 18px;
  background-color: hsla(0, 0%, 100%, 0); /* 完全透明 */
  border: 1px solid #533f1f;
  color: #533f1f;
}
.payjob-but{
  margin-top: 20px;
  margin-bottom: 30px;
}
.pay-job-dia .vipServe-content {
  border-top: 2px solid rgba(230, 230, 230, 0.5);
  padding: 50px 50px 80px;
}
.pay-job-view .payjob .item-sl{
  border: 1px solid #FE6002;
  background-color: #fffaf7;
}
.pay-job-view{
  padding: 20px 40px;
}
.payjob{
  display: flex;
  justify-content: center;
}
.payjob-item{
  width: 166px;
  height: 48px;
  display: flex;
  align-items: center;
  border: 1px solid#CCCCCC;
  border-radius: 4px;
  margin-right: 48px;
  cursor: pointer;
}
.payjob-title-sl{
  font-size: 16px;
  font-weight: 600;
  color: #191919;
  line-height: 19px;
}
.pay-job-font-div{
  border-radius: 15px;
  background: linear-gradient(to top right, #fbf9e8, #fde2bb);
  padding: 50px 0px 20px 0px;
  text-align: center
}
>>> .pay-job-remind .el-dialog .el-dialog__header{
  padding: 0;
}
.pay-job-recommend{
  background-color: #fa6a31;
  width: 70px;
  height: 40px;
  line-height: 40px;
  color: white;
  font-size: 18px;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 0 13px 0 13px;
}
</style>