<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-11-13 18:09:48
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-11 13:48:21
 * @FilePath: /lcwpcvue/src/components/comUser/companyUserIndex.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="company-bas-inf">
    <div class="companyinf-left-view">
      <div class="top-company-basinf-view">
        <div class="up-look-view">
          <!--          <div>
            <img src="@/assets/companyinf_uptime_img.webp" alt="" />
            <span>更新于 2小时内</span>
          </div>-->
          <div>
            <img src="@/assets/companyinf_look_img.webp" alt="" />
            <span>浏览 {{ userInf.hits }}</span>
          </div>
        </div>

        <div class="companyinf-basin">
          <zczheardimg
            :lookImgPath="avatar"
            @success-img-path="successimgpath"
          />

          <!-- <img :src="avatar" class="com-avatar-img" alt="" /> -->
          <div class="right-view">
            <div class="name-status-view">
              <span>{{ userInf.name }}</span>
              <img :src="userInf.vip_icon" alt="" />
              <img :src="userInf.yyzz_status_icon" alt="" />
            </div>
            <div class="type-num-are-view">
              <span>{{ userInf.hy_name }}</span>
              <el-divider direction="vertical"></el-divider>
              <span>{{ userInf.mun }}</span>
              <el-divider
                direction="vertical"
                v-if="userInf.area != ''"
              ></el-divider>
              <span v-show="userInf.area != ''">店面{{ userInf.area }}m²</span>
            </div>
          </div>
        </div>
        <div class="company-tag-edit-view">
          <div class="tag-view">
            <span v-for="(item, idx) in welfare" :key="idx">
              {{ item }}
            </span>
          </div>
          <div class="edit-btn-view">
            <el-button @click="toEdtComInf" type="primary"
              >编辑公司信息</el-button
            >
          </div>
        </div>
      </div>
      <div class="min-payvip-img-view">
        <img
          @click="changeShoeidFun(19)"
          src="../../assets/companyinf_tovip_img.webp"
          alt=""
        />
      </div>

      <div class="com-white talentsmanager-positionmanager">
        <div class="top-title-view">
          <div class="title">
            <span class="xian"></span>
            <span>人才管理</span>
          </div>
          <div class="right-view" @click="toResumeManage('received')">
            <span>去管理</span>
            <i class="el-icon-d-arrow-right"></i>
          </div>
        </div>
        <div class="man-items">
          <div class="item" @click="toResumeManage('received')">
            <el-badge :value="userInf.shoudao" :hidden="userInf.shoudao==0">
              <div class="numbre">{{ userInf.received_resume }}</div>
            </el-badge>
            <div class="text">收到简历</div>
          </div>
          <div class="item" @click="toComInteractionPage('2')">
            <div class="numbre">{{ userInf.link_num }}</div>
            <div class="text">沟通过</div>
          </div>
          <!-- <el-divider direction="vertical"></el-divider> -->
          <div class="item" @click="toWaitingInterviewPage">
            <div class="numbre">{{ userInf.wait_interview }}</div>
            <div class="text">待面试</div>
          </div>
          <!-- <el-divider direction="vertical"></el-divider> -->
          <div class="item" @click="toComInteractionPage('1')">
            <div class="numbre">{{ userInf.collection_num }}</div>
            <div class="text">我的收藏</div>
          </div>
        </div>
      </div>
      <div class="posimanage-view">
        <div class="com-white-posima talentsmanager-positionmanager">
          <div class="top-title-view">
            <div class="title">
              <span class="xian"></span>
              <span>职位管理</span>
            </div>
            <div class="right-view" @click="toJobManage('ing')">
              <span>去管理</span>
              <i class="el-icon-d-arrow-right"></i>
            </div>
          </div>
          <div class="man-items">
            <div class="item" @click="toJobManage('ing')">
              <div class="numbre">{{ jobNum.recruiting }}</div>
              <div class="text">招聘中</div>
            </div>
            <div class="item" @click="toJobManage('wait')">
              <div class="numbre">{{ jobNum.pending_dlete }}</div>
              <div class="text">待审核</div>
            </div>
            <div class="item" @click="toJobManage('down')">
              <div class="numbre">{{ jobNum.sold_out }}</div>
              <div class="text">已下架</div>
            </div>
          </div>
        </div>
        <div class="right-btn">
          <img
            src="../../assets/companyinf_addposi_img.webp"
            @click="toReleaseJob()"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="company-right-compon">
      <zpbb></zpbb>
      <companyqy></companyqy>
    </div>
    <ComDialog
      :isopen="isOpenDialog"
      dialogTetle="关联账号"
      @dialogFrameEvent="dialogFrameEvent"
      myWidth="450px"
    >
      <div class="company-page-dialog">
        <div style="font-size: 16px; line-height: 30px">
          <span style="color: #fe6002">{{ accoutObj.name }}</span>
          <span v-show="accoutObj.remind === '0'">
          请求将您关联为其子账户<br />
          同意后您可使用其VIP权限<br />
          同时他也会获取您的所有信息和权限
          </span>
          <span v-show="accoutObj.remind === '1'">
          已取消您的子账号功能<br />
          其VIP权限您也将不能使用。<br /><br />
          <span style="color: #191919">您可自主升级VIP会员</span>
          </span>
        </div>
        <div
          style="
            width: 100%;
            padding: 40px 0 10px 0;
            display: flex;
            justify-content: flex-end;
          "
        >
          <div v-show="accoutObj.remind === '0'">
            <el-button @click="accoutEvent(3)">拒 绝</el-button>
            <el-button type="primary" @click="accoutEvent(2)">同 意</el-button>
          </div>
          <div v-show="accoutObj.remind === '1'">
            <el-button type="primary" @click="openVipPage(2)">升级VIP</el-button>
            <el-button @click="closeRemindDia()">关闭</el-button>
          </div>
        </div>
      </div>
    </ComDialog>

    <!-- <zczheardimg
      :cropperDialogShow="cropperDialogShow"
      :img="cutImg"
    ></zczheardimg> -->
  </div>
</template>

<script>
import zpbb from "@/components/comUser/zpbb.vue";
import companyqy from "@/components/comUser/rightMyQy.vue";
import { updateLogo } from "@/api/commen/login.js";
import { getComParams } from "@/api/commen/company";
import {accountHandle, isHaveConfirmed, toCancelConfirm} from "@/api/compe/subAccountApi";
import ComDialog from "@/components/comDialog.vue";
export default {
  props: {
    onUpdate: Function,
  },
  components: {
    ComDialog,
    zpbb,
    companyqy,
  },
  data() {
    return {
      updateUrl: process.env.VUE_APP_UPLOUD + "/api/upload/uploadCos",
      userInf: {},
      avatar: "",
      jobNum: {
        recruiting: 0, //招聘中
        pending_dlete: 0, //待审核
        sold_out: 0, //已下架
      },
      welfare: [],
      isOpenDialog: false,
      accoutObj: {},
      cropperDialogShow: false,
      cutImg: "",
    };
  },
  created() {
    this.$store.dispatch("user/setInfo").then(() => {
      this.userInf = this.$store.state.user.userinf;
      this.avatar = this.$store.state.user.avatar;
    });
    let myWelfare = this.$store.getters.userinf.welfare;
    if (myWelfare) {
      this.welfare = myWelfare.split(",");
    }
    this.getCompanyParams();
    if (
      localStorage.getItem("token") &&
      localStorage.getItem("usertype") === "2"
    ) {
      isHaveConfirmed().then((e) => {
        console.log(e);
        if (e.data.id) {
          this.accoutObj = e.data;
          this.isOpenDialog = true;
        }
      });
    }
  },
  methods: {
    closeRemindDia(){
      this.isOpenDialog = false;
      toCancelConfirm(this.accoutObj.id).then(()=>{});
    },
    openVipPage(){
      this.$router.push('/vippaypage')
      toCancelConfirm(this.accoutObj.id).then(()=>{});
    },
    selectChange(e) {
      console.log(e);
      this.cropperDialogShow = true;
    },
    successimgpath(e) {
      console.log("eeeee上传成功了", e);
      var logo = e.data.httpCosUrl;
      updateLogo({
        logo: e.data.cosUrl,
      })
        .then((result) => {
          console.log("更新头像", result);
          this.avatar = logo;
          this.$store.dispatch("user/setInfo").then(() => {
            // location.reload();
          });
        })
        .catch(() => {});
    },
    accoutEvent(status) {
      accountHandle(this.accoutObj.id, status).then(() => {
        this.$message.success("成功");
      });
      this.dialogFrameEvent();
    },
    dialogFrameEvent() {
      this.isOpenDialog = false;
    },
    getCompanyParams() {
      getComParams().then((res) => {
        this.jobNum.recruiting = res.data.recruiting;
        this.jobNum.pending_dlete = res.data.pending_dlete;
        this.jobNum.sold_out = res.data.sold_out;
      });
    },
    toEdtComInf() {
      this.$router.push(`/companyinfdetail`);
    },
    changeShoeidFun() {
      this.$router.push(`/vippaypage`);
    },
    toReleaseJob() {
      this.$router.push("/releasejob");
    },
    toResumeManage(id) {
      this.$router.push(`/resumemanage?tabid=${id}`);
    },
    toJobManage(type) {
      this.$router.push(`/jobmanage?tabid=${type}`);
    },
    toComChatedPahe() {
      this.$router.push(`/companychateduser`);
    },
    toCollectionPage() {
      this.$router.push(`/companycollectionuser`);
    },
    toWaitingInterviewPage() {
      this.$router.push(`/waitinginterview`);
    },
    toComInteractionPage(e) {
      this.$router.push(`/companyinteraction?tabid=${e}`);
    },
    // waitinginterview
    handleAvatarSuccess(e) {
      var logo = e.data.httpCosUrl;
      updateLogo({
        logo: e.data.cosUrl,
      })
        .then((result) => {
          console.log("更新头像", result);
          this.avatar = logo;
          this.$store.dispatch("user/setInfo").then(() => {
            // location.reload();
          });
        })
        .catch(() => {});
    },
  },
};
</script>


<style>
.company-bas-inf {
  display: flex;
}
.top-company-basinf-view {
  width: 778px;
  background-color: #fff;
  border-radius: 16px;
  padding: 24px 24px 19px;
}
.top-company-basinf-view .up-look-view {
  display: flex;
  align-items: center;
  justify-content: end;
}
.top-company-basinf-view .up-look-view div {
  display: flex;
  align-items: center;
  font-size: 14px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 16px;
  margin-left: 36px;
}
.top-company-basinf-view .up-look-view div img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.company-bas-inf .companyinf-basin {
  display: flex;
  align-items: center;
}
.company-bas-inf .companyinf-basin .com-avatar-img {
  width: 88px;
  height: 88px;
  border-radius: 50%;
}

.companyinf-basin .right-view {
  margin-left: 18px;
}

.companyinf-basin .right-view .name-status-view {
  display: flex;
  align-items: center;
  font-size: 24px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
}

.companyinf-basin .right-view .name-status-view img {
  width: auto;
  height: 18px;
  margin-left: 16px;
}
.companyinf-basin .right-view .type-num-are-view {
  font-size: 18px;

  font-weight: 400;
  color: #191919;
  line-height: 24px;
  margin-top: 17px;
}
.company-tag-edit-view {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 20px;
}
.company-tag-edit-view .tag-view span {
  display: inline-block;
  background-color: #f5f6fa;
  border-radius: 8px;
  padding: 7px 16px;
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  line-height: 19px;
  margin-right: 10px;
  margin-bottom: 5px;
}
.company-tag-edit-view .edit-btn-view .el-button.el-button--primary {
  height: 44px;
  width: 160px;
  font-size: 18px;

  font-weight: 600;
  border-radius: 8px;
  border: none;
}
.min-payvip-img-view {
  margin-top: 24px;
}
.min-payvip-img-view img {
  width: 826px;
  height: 80px;
}
.com-white {
  width: 778px;
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
}
.com-white-posima {
  width: 558px;
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
}
.talentsmanager-positionmanager {
  margin-top: 24px;
}
.talentsmanager-positionmanager .top-title-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.talentsmanager-positionmanager .top-title-view .title {
  display: flex;
  align-items: center;
  font-size: 20px;

  font-weight: 600;
  color: #222222;
  line-height: 28px;
}
.talentsmanager-positionmanager .top-title-view .title .xian {
  display: inline-block;
  width: 4px;
  height: 20px;
  background-color: #fe6002;
  margin-right: 16px;
}
.talentsmanager-positionmanager .top-title-view .right-view {
  font-size: 14px;

  font-weight: 600;
  color: #ff8136;
  line-height: 16px;
}
.talentsmanager-positionmanager .man-items {
  display: flex;
  align-items: center;
  /* justify-content: space-around; */
  margin-top: 28px;
}

.talentsmanager-positionmanager .man-items .item {
  text-align: center;
  border-right: 1px solid #e7e8eb;
  flex: 1;
}
.talentsmanager-positionmanager .man-items .item:last-child {
  border: none;
}
.talentsmanager-positionmanager .man-items .item .numbre {
  font-size: 32px;

  font-weight: 600;
  color: #222;
  line-height: 38px;
}
.talentsmanager-positionmanager .man-items .item .text {
  font-size: 14px;

  font-weight: 400;
  color: #666666;
  line-height: 16px;
  margin-top: 8px;
}
.posimanage-view {
  display: flex;
}
.posimanage-view .right-btn {
  margin-top: 20px;
  margin-left: 33px;
}
.posimanage-view .right-btn img {
  width: 187px;
  height: 181px;
}
.company-right-compon {
  margin-left: 24px;
}
.man-items .item:hover {
  border-radius: 10px;
  cursor: pointer;
}
.company-bas-inf .company-page-dialog {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}
.company-bas-inf .company-page-dialog .el-button {
  width: 160px;
  font-size: 16px;
  font-weight: bold;
}
</style>