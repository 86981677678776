<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2023-07-19 16:18:02
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-06-27 19:09:23
 * @FilePath: /lcwpcvue/src/components/homeSearComp.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <!-- <div style="width: aout; overflow-x: hidden"> -->
  <div
    :class="pagescrollhe > 120 ? 'flot-sear-view' : 'sear-view'"
    class="home-sear-com-page"
  >
    <div
      class="lef-search"
      v-if="!['takeshop', 'transfer'].includes(searchpage)"
    >
      <el-input
        :clearable="true"
        v-model="localSearchKey"
        :placeholder="hintMessage"
        id="search"
        class="input-with-select"
        @clear="clearContent"
      >
        <el-select slot="prepend" v-model="slMrVal" @change="inputSelect">
          <label v-for="item in jobClassArr" :key="item.id">
            <el-popover
              :key="item.id"
              placement="right"
              trigger="hover"
              width="350"
              :visible-arrow="false"
            >
              <div class="popover-show-view">
                <div class="popover-show-title">{{ item.name }}</div>
                <div class="popover-show-items-view">
                  <label v-for="nextitem in item.next_list" :key="nextitem.id">
                    <div
                      class="popo-item"
                      @click="toSearchByClass(nextitem)"
                      @mouseover="popoverItemMouseOver(nextitem.id)"
                      @mouseout="popoverItemMoseOut"
                      :class="
                        popoItemId == nextitem.id ? 'popover-hover-cs' : ''
                      "
                    >
                      {{ nextitem.name }}
                    </div>
                  </label>
                </div>
              </div>
              <el-option slot="reference" :label="item.name" :value="item.id">
              </el-option>
            </el-popover>
          </label>
        </el-select>
        <el-button
          @click="searchFun"
          slot="append"
          type="text"
          class="search-butt"
          >搜 索</el-button
        >
      </el-input>
      <div
        class="rm-ss-top"
        v-if="['home', 'fendJobList', 'talent', 'comlist'].includes(searchpage)"
      >
        <span>热门职位：</span>
        <label v-for="item in hotJobList" :key="item.job_id">
          <span class="ss-item" @click="toSearchByHot(item)">{{
            item.name
          }}</span>
        </label>
      </div>
    </div>

    <div
      class="lef-search"
      v-if="['takeshop', 'transfer'].includes(searchpage)"
    >
      <el-input
        :clearable="true"
        v-model="localSearchKey"
        :placeholder="hintMessage"
        id="search"
        class="input-with-select"
        @clear="clearContent"
      >
        <el-select slot="prepend" v-model="trTkName">
          <el-popover
            placement="right"
            trigger="hover"
            width="350"
            :visible-arrow="false"
          >
            <div class="popover-show-view">
              <div class="popover-show-title"></div>
              <div class="popover-show-items-view"></div>
            </div>
          </el-popover>
        </el-select>
        <el-button
          @click="searchFun"
          slot="append"
          type="text"
          class="search-butt"
          >搜 索</el-button
        >
      </el-input>
    </div>

    <div
      class="right-dl-app-view"
      v-show="
        ['talent', 'home', 'fendJobList', 'partList', 'comlist'].includes(
          searchpage
        )
      "
    >
      <img src="../assets/xzapp_ewm.png" alt="" />
    </div>
    <div
      class="right-tra-btn"
      v-show="searchpage == 'transfer'"
      @click="toAddTransfer"
    >
      <img src="@/assets/transfer-shop-butt.png" />
      <span>发布转店</span>
    </div>
    <div
      class="right-tra-btn"
      v-show="searchpage == 'takeshop'"
      @click="toAddTake"
    >
      <img src="@/assets/transfer-shop-butt.png" />
      <span>发布接店</span>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { getJobClassList } from "@/api/commen/filtrateComp";
import { getHomeHotClass } from "@/api/commen/homeApi.js";
export default {
  data() {
    return {
      usertype: 0,
      // slMrVal: "职位类型",
      trTkName: "找店铺",
      jobClassArr: [],
      hotJobList: [],
      hintMessage: "请输入内容",
      // searchKey: "",
      localSearchKey: "",
      jobId: 0,
      popoItemId: 0,
      elOptionShow: false,
    };
  },
  props: {
    slMrValF: {
      type: String,
      default: "职位类型",
    },
    searchpage: {
      type: String,
    },
    pagescrollhe: {
      type: Number,
    },
    searchKey: {
      type: String,
    },
  },
  computed: {
    slMrVal: {
      get() {
        return this.slMrValF;
      },
      set(newValue) {
        console.log('分类的值值',newValue);
        this.$emit("update:slMrValF", newValue);
      },
    },
  },
  created() {
    this.usertype = localStorage.getItem("usertype");
    if (["takeshop", "transfer"].includes(this.searchpage)) {
      this.trTkName = "找店铺";
      this.hintMessage = "请输入关键字搜索转店标题";
    } else if (["home", "fendJobList", "talent"].includes(this.searchpage)) {
      if (this.usertype === "1") {
        this.hintMessage = "请输入关键字搜索职位,公司";
      } else {
        this.hintMessage = "请输入职位关键字搜索";
      }
    }
    this.getJobClassList();
    this.getHomeHotJob();
  },

  methods: {
    popoverItemMouseOver(popoitemid) {
      this.popoItemId = popoitemid;
    },
    popoverItemMoseOut() {
      this.popoItemId = 0;
    },
    popoverHideFun() {},

    /* 热门职位 */
    getHomeHotJob() {
      let isHave = sessionStorage.getItem("homeHotClass");
      if (isHave) {
        this.hotJobList = JSON.parse(isHave);
      } else {
        getHomeHotClass()
          .then((result) => {
            console.log("热门职位", result);
            this.hotJobList = result.data;
            sessionStorage.setItem(
              "homeHotClass",
              JSON.stringify(this.hotJobList)
            );
          })
          .catch(() => {});
      }
    },
    //获取分类
    getJobClassList() {
      let cacheName = "getJobClassList_fc" + localStorage.usertype;
      let cityData = sessionStorage.getItem(cacheName);
      if (cityData) {
        this.jobClassArr = JSON.parse(cityData);
      } else {
        getJobClassList()
          .then((result) => {
            this.jobClassArr = result.data.list;
            sessionStorage.setItem(
              cacheName,
              JSON.stringify(this.jobClassArr),
              86400
            );
          })
          .catch(() => {});
      }
    },

    toSearchByClass(item) {
      console.log("toSearchByClass:", item);
      this.slMrVal = item.name;
      this.$emit("hotJobs", { id: item.id, keyid: item.keyid });
      /*      if (this.usertype == 1) {
        this.$router.push(`/findjob?classtype=${item.id}`);
      } else {
        this.$router.push(`/findtalentslist?classtype=${item.id}`);
      }*/
    },
    inputSelect(e) {
      console.log("toSearchByClasse2:", e);
      let name = "";
      this.jobClassArr.forEach((element) => {
        if (element.id == e) {
          name = element.name;
        }
      });
      this.slMrVal = e;
      this.$emit("hotJobs", { id: e, keyid: 0, name: name });
    },
    toSearchByHot(item) {
      this.$emit("hotJobs", { id: item.job_id, keyid: item.keyid });
      /*      if (this.usertype == 1) {
        this.$router.push(`/findjob?classtype=${item.job_id}`);
      } else {
        this.$router.push(`/findtalentslist?classtype=${item.job_id}`);
      }*/
    },
    searchFun() {
      this.$emit("toSearch", this.localSearchKey);
    },
    toAddTransfer() {
      this.$router.push("/publishtransffer");
    },
    toAddTake() {
      this.$router.push("/publishtake");
    },
    clearContent() {
      this.$emit("toSearch", "");
    },

    hadlerMouseOver(id) {
      console.log("悬停");
      this.jobId = id;
    },
    hadlerMoseOut() {
      this.jobId = 0;
    },
  },
};
</script>

<style>
.flot-sear-view {
  width: 1920px;
  position: fixed;
  top: 64px;
  z-index: 999;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
  box-shadow: 4px 4px 12px 0px rgba(111, 111, 111, 0.15);
  border-radius: 0 0 10px 10px;
}

.sear-view {
  display: flex;
  align-items: start;
  padding-top: 10px;
  position: relative;
}

/* .input-with-select {
  border-radius: 4px;
} */

/* .sear-view .el-input-group__prepend {
  border-radius: 24px;
} */

.lef-search {
  width: 1060px;
  /* margin-left: 220px; */
}

.lef-search .input-with-select {
  border: 2px solid #fe6002;
  box-shadow: 2px 4px 10px 0px rgba(111, 111, 111, 0.15);
  border-radius: 27px;
  height: 66px;
}

.lef-search .input-with-select .el-select {
  width: 173px;
  border-radius: 108px;
  padding-left: 10px;
}

.lef-search .input-with-select .el-input-group__append {
  border-radius: 0 24px 24px 0;
  width: 90px;
  background-color: #fe6002;
  color: #ffffff;
  font-size: 20px;

  font-weight: 600;
  color: #ffffff;
  text-align: center;
  border: none;
}
.lef-search .input-with-select .el-input-group__prepend {
  border-radius: 27px 0 0 27px;
  background-color: #fff;

  font-weight: 600;
  color: #191919;
  border: none;
}

.lef-search .input-with-select .el-input__inner {
  height: 66px;
  font-size: 20px;
  border-top: none;
  border-bottom: none;
  border-right: none;
}

.lef-search .rm-ss-top {
  font-size: 16px;

  font-weight: 400;
  color: #757575;
  margin-top: 18px;
  margin-left: 33px;
  white-space: nowrap;
  overflow: hidden;
}
.lef-search .rm-ss-top .ss-item {
  display: inline-block;
  margin-left: 20px;
  cursor: pointer;
}

.el-select-dropdown__item span {
  display: inline-block;
  width: 200px;

  font-weight: 400;
  font-size: 16px;
  color: #191919;
  line-height: 19px;
}

.right-dl-app-view {
  margin-left: 110px;
  /* position: absolute;
  right: 20%; */
}
.right-dl-app-view img {
  width: 311px;
  height: 114px;
  display: block;
}
.sear-view .right-tra-btn,
.flot-sear-view .right-tra-btn {
  margin-left: 105px;
  height: 66px;
  width: 311px;
  background-color: #fe6002;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sear-view .right-tra-btn img,
.flot-sear-view .right-tra-btn img {
  display: inline-flex;
  width: 41px;
  height: 41px;
}
.sear-view .right-tra-btn span,
.flot-sear-view .right-tra-btn span {
  display: inline-flex;

  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
  font-style: normal;
}

.sear-view .hot-job-hover-css {
  color: #fe6002;
}
.home-sear-com-page .rm-ss-top .ss-item:hover {
  color: #fe6002;
}

.home-sear-com-page .search-butt {
  width: 130px;
  height: 86px;
  background-color: #999999;
  margin: 0;
}

.el-scrollbar {
  padding: 10px;
}

li.el-select-dropdown__item.hover {
  background-color: #fff5f0;
}
</style>