<!--
 * @Author: 张春朝 springz.mwx@gmail.com
 * @Date: 2024-01-10 13:14:06
 * @LastEditors: 张春朝 springz.mwx@gmail.com
 * @LastEditTime: 2024-08-15 09:47:01
 * @FilePath: /lcwpcvue/src/views/jobManage.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="job-manage-page">
    <div style="height: 30px"></div>
    <div class="common-title-view">
      <span class="left-xian-view"></span>
      <span class="text">职位管理</span>
      <div class="right-add-btn">
        <el-button
          @click="toReleaseJob"
          type="primary"
          round
          icon="el-icon-plus"
          >发布</el-button
        >
      </div>
    </div>

    <div class="coupon-navbar-view">
      <div class="nav-item" @click="changeNavBarId('all')">
        <div class="nav-text" :class="navbarId == 'all' ? 'nav-sltext' : ''">
          全部
        </div>
        <div v-show="navbarId == 'all'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId('isPay')">
        <div class="nav-text" :class="navbarId == 'isPay' ? 'nav-sltext' : ''">
          开放中
        </div>
        <div v-show="navbarId == 'isPay'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId('isNotPay')">
        <div
          class="nav-text"
          :class="navbarId == 'isNotPay' ? 'nav-sltext' : ''"
        >
          待开放
        </div>
        <div v-show="navbarId == 'isNotPay'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId('notPass')">
        <div
          class="nav-text"
          :class="navbarId == 'notPass' ? 'nav-sltext' : ''"
        >
          审核未通过
        </div>
        <div v-show="navbarId == 'notPass'" class="bot-xian"></div>
      </div>
      <div class="nav-item" @click="changeNavBarId('down')">
        <div class="nav-text" :class="navbarId == 'down' ? 'nav-sltext' : ''">
          已关闭
        </div>
        <div v-show="navbarId == 'down'" class="bot-xian"></div>
      </div>
    </div>

    <div class="job-item">
      <jobmanageitem
        :listType="navbarId"
        @refrMyJob="refrMyJob"
        :joblist="jobManageList"
        @colseAndDelMyJob="colseAndDelMyJob"
        @getRefrHistoyList="getRefrHistoyList"
        @onFullJobInMarange="onFullJobInMarange"
        @jobYlFun="jobYlFun"
        @getJobPriceList="getJobPriceList"
        @stopAoutRefresh="stopAoutRefresh"
        @refreshwork="refreshwork"
      ></jobmanageitem>
      <div v-show="jobManageList.length == 0" class="nocoupon-list-view">
        <img class="none-list-img" src="@/assets/transfer_is_null.png" alt="" />
        <div class="none-text">暂无还没有相关的信息</div>
      </div>
      <el-pagination
        v-show="jobManageList.length != 0"
        @current-change="pagesChage"
        background
        layout="prev, pager, next"
        :page-count="allPage"
        :current-page.sync="currentpage"
        class="pag-ina"
      >
      </el-pagination>
    </div>

    <el-dialog
      width="40%"
      title="刷新纪录"
      :visible.sync="refrHistoyListDailogShow"
    >
      <div class="refr-histoy-list-view">
        <label v-for="item in refrHistoyList" :key="item.id">
          <div class="refr-histoy-list-item">
            <span class="tt1">{{ item.is_auto }}</span>
            <span class="tt2">{{ item.ctime }}</span>
          </div>
        </label>
        <div class="zw-text">暂无更多刷新记录~</div>
      </div>
    </el-dialog>

    <el-dialog width="40%" title="增加曝光" :visible.sync="payDialogShow">
      <div class="pay-inf-view">
        <div class="topnav-bar">
          <div
            class="items"
            :class="ratingType == 'integral_job_top' ? 'items-sl' : ''"
            @click="changeRatingType('integral_job_top')"
          >
            <img src="@/assets/job_man_top.png" /><span>置顶</span>
          </div>
          <div
            class="items"
            :class="ratingType == 'com_urgent' ? 'items-sl' : ''"
            @click="changeRatingType('com_urgent')"
          >
            <img src="@/assets/job_man_jp.png" /><span>急聘</span>
          </div>
          <div
            class="items"
            :class="ratingType == 'com_recjob' ? 'items-sl' : ''"
            @click="changeRatingType('com_recjob')"
          >
            <img src="@/assets/job_man_tj.png" /><span>推荐</span>
          </div>
        </div>

        <div class="pay-title1">充值选择</div>
        <div class="pay-title2">{{ jobZdJzList.describe }}</div>
        <div class="pay-chose-list-view">
          <label v-for="item in jobZdJzList.son_list" :key="item.id">
            <div
              class="chose-item"
              :class="choseComboId == item.id ? 'chose-item-sl' : ''"
              @click="chageComboId(item.id, parseInt(item.service))"
            >
              <div class="day-text">{{ item.service }}</div>
              <div class="mony-text">
                <span>￥</span>{{ item.service_price }}
              </div>
            </div>
          </label>
        </div>

        <div class="day-tips-view">
          <div class="tips-title-view">
            <i class="el-icon-info"></i>
            <span>温馨提示：</span>
          </div>
          <div class="days-num-view" v-html="syDaysNumbConten"></div>
        </div>

        <div class="pay-type-view" v-show="payViewShow">
          <div class="paytype-title">支付方式</div>
          <div class="paytype">
            <div
              class="paytype-item"
              @click="changePayType('wxpay')"
              :class="payType == 'wxpay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_wx_img.png" alt="" />
              <span>微信支付</span>
            </div>
            <div
              class="paytype-item"
              @click="changePayType('alipay')"
              :class="payType == 'alipay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
              <span>支付宝支付</span>
            </div>
          </div>
        </div>
        <div v-show="payViewShow">
          <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
            <div class="wxpay-tt1">
              请使用<label>微信</label>扫描二维码完成支付
            </div>
            <div class="wxpay-tt2">
              如无法支付或二维码已过期请点击<label @click="refrWxPayEwm"
                >刷新</label
              >
            </div>
            <img class="wxpay-ewm-img" :src="wxPayImgUrl" alt="" />
            <div class="wxpay-tt2" @click="openAgreementFun">
              购买付费即表示同意<label>《猎宠网VIP服务协议》</label>
            </div>
          </div>
          <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
            <el-button type="primary" @click="toAliPayPage">去支付</el-button>
          </div>
        </div>
        <div v-show="!payViewShow" slot="footer" class="dialog-footer">
          <el-button type="primary" @click="deductionInterest">确认</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog
      width="400px"
      title="自动智能刷新"
      :visible.sync="aoutRefpayDialogShow"
    >
      <div class="pay-inf-view">
        <div class="pay-title1">充值选择</div>
        <div class="pay-title2">{{ jobZdJzList.describe }}</div>
        <div class="pay-chose-list-view">
          <label v-for="item in jobZdJzList.son_list" :key="item.id">
            <div
              class="chose-item"
              :class="choseComboId == item.id ? 'chose-item-sl' : ''"
              @click="chageComboId(item.id, parseInt(item.service))"
            >
              <div class="day-text">{{ item.service }}</div>
              <div class="mony-text">
                <span>￥</span>{{ item.service_price }}
              </div>
            </div>
          </label>
        </div>

        <div class="pay-type-view" v-show="payViewShow">
          <div class="paytype-title">支付方式</div>
          <div class="paytype">
            <div
              class="paytype-item"
              @click="changePayType('wxpay')"
              :class="payType == 'wxpay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_wx_img.png" alt="" />
              <span>微信支付</span>
            </div>
            <div
              class="paytype-item"
              @click="changePayType('alipay')"
              :class="payType == 'alipay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
              <span>支付宝支付</span>
            </div>
          </div>
        </div>
        <div v-show="payViewShow">
          <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
            <div class="wxpay-tt1">
              请使用<label>微信</label>扫描二维码完成支付
            </div>
            <div class="wxpay-tt2">
              如无法支付或二维码已过期请点击<label @click="refrWxPayEwm"
                >刷新</label
              >
            </div>
            <img class="wxpay-ewm-img" :src="wxPayImgUrl" alt="" />
            <div class="wxpay-tt2" @click="openAgreementFun">
              购买付费即表示同意<label>《猎宠网VIP服务协议》</label>
            </div>
          </div>
          <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
            <el-button type="primary" @click="toAliPayPage">去支付</el-button>
          </div>
        </div>
        <div v-show="!payViewShow" slot="footer" class="dialog-footer">
          <el-button type="primary" @click="deductionInterest">确认</el-button>
        </div>
      </div>
    </el-dialog>
    <com-dialog
      :isopen="isOpenAgreement"
      dialogTetle="猎宠网VIP服务协议"
      @dialogFrameEvent="agreementEvent"
      myWidth="1280px"
    >
      <div v-html="vipServe" class="vipServe-content"></div>
    </com-dialog>
    <!-- <el-dialog
      width="40%"
      :title="jobZdJzList.name"
      :visible.sync="payDialogShow"
    >
      <div class="pay-inf-view">
        <div class="pay-title1">充值选择</div>
        <div class="pay-title2">{{ jobZdJzList.describe }}</div>
        <div class="pay-chose-list-view">
          <label v-for="item in jobZdJzList.son_list" :key="item.id">
            <div
              class="chose-item"
              :class="choseComboId == item.id ? 'chose-item-sl' : ''"
              @click="chageComboId(item.id)"
            >
              <div class="day-text">{{ item.service }}</div>
              <div class="mony-text">
                <span>￥</span>{{ item.service_price }}
              </div>
            </div>
          </label>
        </div>
        <div class="pay-type-view">
          <div class="paytype-title">支付方式</div>
          <div class="paytype">
            <div
              class="paytype-item"
              @click="changePayType('wxpay')"
              :class="payType == 'wxpay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_wx_img.png" alt="" />
              <span>微信支付</span>
            </div>
            <div
              class="paytype-item"
              @click="changePayType('alipay')"
              :class="payType == 'alipay' ? 'item-sl' : ''"
            >
              <img src="@/assets/vip_paytype_zfb_img.png" alt="" />
              <span>支付宝支付</span>
            </div>
          </div>
        </div>
        <div class="wx-pay-img-view" v-show="payType == 'wxpay'">
          <div class="wxpay-tt1">
            请使用<label>微信</label>扫描二维码完成支付
          </div>
          <div class="wxpay-tt2">
            如无法支付或二维码已过期请点击<label @click="refrWxPayEwm"
              >刷新</label
            >
          </div>
          <img class="wxpay-ewm-img" :src="wxPayImgUrl" alt="" />
          <div class="wxpay-tt2">
            购买付费即表示同意<label>《猎宠网VIP服务协议》</label>
          </div>
        </div>
        <div v-show="payType == 'alipay'" slot="footer" class="dialog-footer">
          <el-button type="primary" @click="toAliPayPage">去支付</el-button>
        </div>
      </div>
    </el-dialog> -->

    <el-dialog
      width="30%"
      title="温馨提示"
      :visible.sync="refrAccAoutDailogShow"
    >
      <div class="account-aout-view">
        <div class="desptext">
          您的职位自动智能刷新权限还剩余<label>{{ autoRwcNum }}</label
          >，请设置该职位自动刷新天数：
        </div>
        <div class="chose-day-view">
          <div
            class="day-item"
            @click="choseCutNum(1)"
            :class="cutNuber == 1 ? 'day-item-sl' : ''"
          >
            1天
          </div>
          <div
            class="day-item"
            @click="choseCutNum(3)"
            :class="cutNuber == 3 ? 'day-item-sl' : ''"
          >
            3天
          </div>
          <div
            class="day-item"
            @click="choseCutNum(7)"
            :class="cutNuber == 7 ? 'day-item-sl' : ''"
          >
            7天
          </div>
          <div
            class="day-item"
            @click="choseCutNum(15)"
            :class="cutNuber == 15 ? 'day-item-sl' : ''"
          >
            15天
          </div>
        </div>

        <div class="foot-btn">
          <el-button @click="refrAccAoutDailogShow = false">取消</el-button>
          <el-button type="primary" @click="postCutNumber">确定</el-button>
        </div>
      </div>
    </el-dialog>
    <PayJobDia
      :isopen="isOpenPayJob"
      :params="payJobParams"
      @payJobChang="payJobChang"
    ></PayJobDia>
  </div>
</template>

<script>
import jobmanageitem from "@/components/comUser/jobManageItem.vue";
import {
  getMyJobManageNube,
  getMyJobManageList,
  refrMyJob,
  colseMyFullJob,
  colseMyPartTimeJob,
  delMyFullJob,
  delMyPartTimeJob,
  getRefrHistoyList,
  onFullJobInMarange, //审核上架全职职位
  fullTimeUpAgain, //直接上架全职职位
  getJobPriceList,
  getJobManagerServices,
  getJobManagerTopUgRec,
  deductionInterest,
} from "@/api/compe/jobsApi.js";
import { submitOrder } from "@/api/commen/payApi.js";
import { getUserProfile } from "@/api/commen/login";
import ComDialog from "@/components/comDialog.vue";
import PayJobDia from "@/components/jobClass/payJob.vue";
// import { parseTime } from "@/utils/index.js";
export default {
  props: {
    onUpdate: Function,
  },
  components: {
    PayJobDia,
    ComDialog,
    jobmanageitem,
  },
  data() {
    return {
      payJobParams: {},
      isOpenPayJob: false,
      refrHistoyListDailogShow: false,
      navbarId: "all",
      jobManageNum: {},
      jobManageList: [],
      refrHistoyList: [],
      payDialogShow: false,
      payType: "wxpay",
      jobZdJzList: [],
      jobId: "",
      comboType: "",
      choseComboId: "",
      wxPayImgUrl: "",
      aliNatoUrl: "",
      autoRwcNum: 0,
      cutNuber: 0,
      refrAccAoutDailogShow: false,
      ratingType: "integral_job_top",
      payViewShow: false,
      syDaysNumbConten: "",
      qyCutNum: 0,
      isOpenAgreement: false,
      vipServe: "",
      baoguangType: "all",
      aoutRefpayDialogShow: false,
      pageIndex: 1,
      allPage: 1,
      currentpage: 1,
    };
  },
  created() {
    this.getMyJobManageNube();
    if (this.$route.query.tabid) {
      this.navbarId = this.$route.query.tabid;
    }
   
    this.getMyJobManageList(this.navbarId);
  },
  methods: {
    openAgreementFun() {
      console.log("打开vip协议");
      if (!this.vipServe) {
        getUserProfile("/vipServe").then((res) => {
          this.vipServe = res.data;
          this.isOpenAgreement = true;
        });
      } else {
        this.isOpenAgreement = true;
      }
    },
    agreementEvent() {
      this.isOpenAgreement = false;
    },
    /* 获取数字信息 */
    getMyJobManageNube() {
      getMyJobManageNube()
        .then((result) => {
          console.log("获取数字信息", result);
          this.jobManageNum = result.data;
        })
        .catch(() => {});
    },

    pagesChage(e) {
      console.log("chage", e);
      this.pageIndex = e;
      window.scrollTo(200, 200);
      this.getMyJobManageList(this.navbarId);
    },

    /* 获取职位列表 */
    getMyJobManageList(flag) {
      getMyJobManageList({ column: flag, page: this.pageIndex })
        .then((result) => {
          console.log("////////", result);
          this.allPage = parseInt(result.data.allpage);
          // if (flag == "ing") {
          result.data.list.forEach((element) => {
            element.time = new Date(element.update_time * 1000).format(
              "yyyy-MM-dd hh:mm:ss"
            );
          });
          // } else if (flag == "down") {
          //   result.data.list.forEach((element) => {
          //     element.time = new Date(element.update_time * 1000).format(
          //       "yyyy-MM-dd hh:mm:ss"
          //     );
          //   });
          // }
          console.log(result.data.list);
          this.jobManageList = result.data.list;
        })
        .catch(() => {});
    },
    refrMyJob(type, id) {
      var data = {};
      if (type == "manual") {
        data = {
          job_id: id,
          job_name: name,
          type: "auto_refresh",
          refreshType: "execRefresh",
        };
      } else {
        data = {
          type: "auto_refresh",
          refreshType: "query",
        };
      }
      refrMyJob(data)
        .then((result) => {
          console.log("刷新职位", result);
          if (result.code == 200) {
            this.$message({
              message: "刷新成功",
              type: "success",
            });
            this.getMyJobManageList(this.navbarId);
          }
        })
        .catch(() => {});
    },

    stopAoutRefresh(id, num) {
      this.$confirm(`您该职位设置的自动智能刷新还剩余${num}天`, "提示", {
        confirmButtonText: "停止刷新",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          getJobManagerServices({
            type: "auto_refresh", //固定
            refreshType: "cancel", //固定
            id: id, //职位ID
          })
            .then((result) => {
              this.getMyJobManageList(this.navbarId);
              console.log("停止刷新", result);
            })
            .catch(() => {});
        })
        .catch(() => {});
    },
    colseAndDelMyJob(type, id, ispart) {
      if (type == "colse") {
        this.$confirm("确定要关闭该职位吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (ispart == 1) {
              colseMyPartTimeJob({
                id: id,
                r_status: 2,
              })
                .then((result) => {
                  console.log("关闭职位", result);
                  if (result.code == 200) {
                    this.$message({
                      message: result.msg,
                      type: "success",
                    });
                  }
                  this.getMyJobManageList(this.navbarId);
                  this.getMyJobManageNube();
                })
                .catch(() => {});
            } else {
              colseMyFullJob({ id: id })
                .then((result) => {
                  console.log("关闭职位", result);
                  if (result.code == 200) {
                    this.$message({
                      message: result.msg,
                      type: "success",
                    });
                  }
                  this.getMyJobManageList(this.navbarId);
                  this.getMyJobManageNube();
                })
                .catch(() => {});
            }
          })
          .catch(() => {});
      } else {
        this.$confirm("确定要删除该职位吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            if (ispart == 1) {
              delMyPartTimeJob({
                id: id,
              })
                .then((result) => {
                  console.log("删除职位", result);
                  if (result.code == 200) {
                    this.$message({
                      message: result.msg,
                      type: "success",
                    });
                  }
                  this.getMyJobManageList(this.navbarId);
                  this.getMyJobManageNube();
                })
                .catch(() => {});
            } else {
              delMyFullJob({ id: id })
                .then((result) => {
                  console.log("删除职位", result);
                  if (result.code == 200) {
                    this.$message({
                      message: result.msg,
                      type: "success",
                    });
                  }
                  this.getMyJobManageList(this.navbarId);
                  this.getMyJobManageNube();
                })
                .catch(() => {});
            }
          })
          .catch(() => {});
      }
    },

    changeRatingType(name) {
      this.ratingType = name;
      // this.jobId = id;
      this.getJobPriceList(name, this.jobId);
    },
    /* 获取套餐类型 */
    getJobPriceList(type, id) {
      console.log(id);
      this.getJobManagerTopUgRec();
      this.jobId = id;
      if (type == "integral_job_top") {
        this.comboType = 10;
      } else if (type == "com_urgent") {
        this.comboType = 11;
      } else if (type == "com_recjob") {
        this.comboType = 12;
      } else if (type == "job_auto") {
        this.comboType = 13;
      }
      if (type == "job_auto") {
        getJobManagerServices({
          type: "auto_refresh",
          refreshType: "query",
        })
          .then((res) => {
            console.log("获取权限剩余数量", res);
            if (res.code == 0) {
              getJobPriceList({
                rating_type: type,
              })
                .then((result) => {
                  console.log("获取套餐列表", result);
                  this.baoguangType = "aoutref";
                  this.jobZdJzList = result.data.list;
                  this.choseComboId = result.data.list.son_list[0].id;
                  this.aoutRefpayDialogShow = true;
                  this.ratingType = "auto_refresh";
                  this.chageComboId(result.data.list.son_list[0].id, 1);
                })
                .catch(() => {});
            } else {
              this.autoRwcNum = res.data.auto_refresh;
              this.refrAccAoutDailogShow = true;
            }
          })
          .catch(() => {});
      } else {
        getJobPriceList({
          rating_type: type,
        })
          .then((result) => {
            console.log("获取套餐列表", result);
            this.jobZdJzList = result.data.list;
            this.choseComboId = result.data.list.son_list[0].id;
            this.payDialogShow = true;
            this.chageComboId(result.data.list.son_list[0].id, 1);
          })
          .catch(() => {});
      }
    },

    getJobManagerTopUgRec() {
      getJobManagerTopUgRec()
        .then((result) => {
          this.topUgRecInf = result.data.statis;
          if (this.ratingType == "integral_job_top") {
            this.syDaysNumbConten = `您的职位置顶权益还剩<span style="font-size: 20px;font-weight: bold"> ${result.data.statis.job_top_time} </span>天可用，请选择设定的天数`;
          } else if (this.ratingType == "com_urgent") {
            this.syDaysNumbConten = `您的职位急招权益还剩<span style="font-size: 20px;font-weight: bold"> ${result.data.statis.urgent_time} </span>天可用，请选择设定的天数`;
          } else if (this.ratingType == "com_recjob") {
            this.syDaysNumbConten = `您的职位推荐权益还剩<span style="font-size: 20px;font-weight: bold"> ${result.data.statis.rec_time} </span>天可用，请选择设定的天数`;
          }
        })
        .catch(() => {});
    },

    chageComboId(combid, days) {
      console.log("00000000", this.ratingType);
      /* 生成订单之前查查询点数 */

      if (this.ratingType == "integral_job_top") {
        if (parseInt(this.topUgRecInf.job_top_time) > 0) {
          this.payViewShow = false;
          if (parseInt(this.topUgRecInf.job_top_time) >= days) {
            console.log();
            this.qyCutNum = days;
            this.choseComboId = combid;
          } else {
            this.$message({
              message: `当前可用权益不足，最多可设置${this.qyCutNum}天`,
              type: "warning",
            });
          }
        } else {
          this.choseComboId = combid;
          submitOrder({
            pay_type: this.payType,
            type: this.comboType,
            rating_id: this.choseComboId,
            job_id: this.jobId,
          })
            .then((result) => {
              this.payViewShow = true;
              console.log("生成订单", result);
              if (this.payType == "wxpay") {
                this.wxPayImgUrl = result.data.pay_img;
              } else {
                this.aliNatoUrl = result.data.url;
              }
            })
            .catch(() => {});
        }
      } else if (this.ratingType == "com_urgent") {
        if (parseInt(this.topUgRecInf.urgent_time) > 0) {
          this.payViewShow = false;

          if (parseInt(this.topUgRecInf.urgent_time) >= days) {
            console.log();
            this.qyCutNum = days;

            this.choseComboId = combid;
          } else {
            this.$message({
              message: `当前可用权益不足，最多可设置${this.qyCutNum}天`,
              type: "warning",
            });
          }
        } else {
          this.choseComboId = combid;
          submitOrder({
            pay_type: this.payType,
            type: this.comboType,
            rating_id: this.choseComboId,
            job_id: this.jobId,
          })
            .then((result) => {
              this.payViewShow = true;

              console.log("生成订单", result);
              if (this.payType == "wxpay") {
                this.wxPayImgUrl = result.data.pay_img;
              } else {
                this.aliNatoUrl = result.data.url;
              }
            })
            .catch(() => {});
        }
      } else if (this.ratingType == "com_recjob") {
        this.payViewShow = false;
        if (parseInt(this.topUgRecInf.rec_time) > 0) {
          if (parseInt(this.topUgRecInf.rec_time) >= days) {
            console.log();
            this.qyCutNum = days;
            this.choseComboId = combid;
          } else {
            this.$message({
              message: `当前可用权益不足，最多可设置${this.qyCutNum}天`,
              type: "warning",
            });
          }
        } else {
          this.choseComboId = combid;
          submitOrder({
            pay_type: this.payType,
            type: this.comboType,
            rating_id: this.choseComboId,
            job_id: this.jobId,
          })
            .then((result) => {
              this.payViewShow = true;
              console.log("生成订单", result);
              if (this.payType == "wxpay") {
                this.wxPayImgUrl = result.data.pay_img;
              } else {
                this.aliNatoUrl = result.data.url;
              }
            })
            .catch(() => {});
        }
      } else if (this.ratingType == "auto_refresh") {
        this.choseComboId = combid;
        submitOrder({
          pay_type: this.payType,
          type: this.comboType,
          rating_id: this.choseComboId,
          job_id: this.jobId,
        })
          .then((result) => {
            this.payViewShow = true;
            console.log("生成订单", result);
            if (this.payType == "wxpay") {
              this.wxPayImgUrl = result.data.pay_img;
            } else {
              this.aliNatoUrl = result.data.url;
            }
          })
          .catch(() => {});
      }
      // })
      // .catch(() => {});
    },

    /* 有权益的扣除权益 */
    deductionInterest() {
      deductionInterest({
        type: this.ratingType,
        num: this.qyCutNum,
        jobId: this.jobId,
      })
        .then((result) => {
          this.$message({
            message: result.msg,
            type: "success",
          });
          this.refrAccAoutDailogShow = false;
          this.payDialogShow = false;
        })
        .catch(() => {});
    },

    /* 获取刷新记录 */
    getRefrHistoyList(id) {
      getRefrHistoyList({
        type: "auto_refresh",
        refreshType: "autoList",
        id: id,
      })
        .then((result) => {
          this.refrHistoyList = result.data.list;
          this.refrHistoyListDailogShow = true;
          console.log("获取刷新历史记录", result);
        })
        .catch(() => {});
    },
    payJobDia(params, jobId, jobType) {
      params.job_id = jobId;
      params.job_type = jobType;
      this.payJobParams = params;
      this.isOpenPayJob = true;
    },
    payJobChang(ch) {
      console.log("payJobChang.....>>>", ch);
      this.isOpenPayJob = false;
      this.getMyJobManageList(this.navbarId);
      this.getMyJobManageNube();
    },
    /* 重新上架 */
    onFullJobInMarange(id, state) {
      this.$confirm("确定要开放该职位吗？", "提醒", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (state == 0 || state == 3) {
            onFullJobInMarange({ id: id })
              .then((result) => {
                console.log("重新上架", result);
                // if (result.code == 200) {
                //
                // }
                if (result.data.isPay) {
                  this.payJobDia(result.data.isPay, id, "no"); //jobType为no表示前端拿不到，后端去拿
                } else {
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                }
                // this.getMyJobManageList(this.navbarId);
                // this.getMyJobManageNube();
              })
              .catch(() => {});
          } else if (state == 1) {
            fullTimeUpAgain({ id: id })
              .then((result) => {
                if (result.data.isPay) {
                  this.payJobDia(result.data.isPay, id, "no"); //jobType为no表示前端拿不到，后端去拿
                } else {
                  this.$message({
                    message: result.msg,
                    type: "success",
                  });
                }
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    },
    jobYlFun(e, id) {
      console.log(e, id);
    },
    changeNavBarId(e) {
      this.navbarId = e;
      this.pageIndex = 1;
      this.currentpage = 1;
      this.getMyJobManageList(e);
    },
    changeShoeidFun(key) {
      this.onUpdate(key);
    },
    changePayType(type) {
      this.payType = type;
      submitOrder({
        pay_type: type,
        type: this.comboType,
        rating_id: this.choseComboId,
        job_id: this.jobId,
      })
        .then((result) => {
          console.log("生成订单", result);
          if (this.payType == "wxpay") {
            this.wxPayImgUrl = result.data.pay_img;
          } else {
            this.aliNatoUrl = result.data.url;
          }
        })
        .catch(() => {});
    },
    toReleaseJob() {
      this.$router.push("/releasejob");
    },
    toAliPayPage() {
      window.open(this.aliNatoUrl, "_blank");
    },
    choseCutNum(num) {
      if (num > this.autoRwcNum) {
        this.$message({
          message: "剩余数量不够",
          type: "error",
        });
      } else {
        this.cutNuber = num;
      }
    },
    postCutNumber() {
      getJobManagerServices({
        autoAefreshNum: this.cutNuber, //天数
        type: "auto_refresh", //固定
        refreshType: "set", //固定
        id: this.jobId, //职位ID
      })
        .then((result) => {
          console.log("扣除", result);
          this.getMyJobManageList(this.navbarId);
          this.refrAccAoutDailogShow = false;
          if (result.code == 200) {
            this.$message({
              message: "设置成功",
              type: "success",
            });
          }
        })
        .catch(() => {});
    },
    /* 手动刷新 */
    refreshwork(name, id) {
      getJobManagerServices({
        job_id: id,
        job_name: name,
        type: "auto_refresh",
        refreshType: "execRefresh",
      })
        .then((result) => {
          if (result.code == 200) {
            this.$message({
              message: "刷新成功",
              type: "success",
            });
          }
        })
        .catch(() => {});
    },

    refrWxPayEwm() {},
  },
};
</script>

<style>
.pay-inf-view {
  padding-bottom: 30px;
}

.pay-inf-view .topnav-bar {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.pay-inf-view .topnav-bar .items {
  font-weight: 500;
  font-size: 16px;
  color: #757575;
  line-height: 19px;
  background-color: #f1f1f1;
  flex: 1;
  text-align: center;
  padding: 12px 0;
}
.pay-inf-view .topnav-bar .items-sl {
  color: #222222;
  background-color: #fff;
}
.pay-inf-view .topnav-bar .items img {
  width: 19px;
  height: 19px;
  margin-right: 4px;
}

.pay-inf-view .pay-title1 {
  /* border-top: 2px solid#EAECF5; */
  padding-top: 30px;
  padding-left: 34px;
  font-size: 16px;

  font-weight: 600;
  color: #222222;
  line-height: 19px;
}
.pay-inf-view .pay-title2 {
  margin-left: 34px;
  margin-top: 6px;
  font-size: 12px;

  font-weight: 400;
  color: #757575;
  line-height: 14px;
}
.pay-inf-view .pay-chose-list-view {
  margin-top: 24px;
  margin-left: 10px;
}
.pay-inf-view .pay-chose-list-view .chose-item {
  width: 97px;
  height: 100px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #e7e8eb;
  border-radius: 4px;
  margin-left: 14px;
}
.pay-inf-view .pay-chose-list-view .chose-item-sl {
  border: 1px solid #f0bf3e;
  background-color: #fffbee;
}

.pay-inf-view .pay-chose-list-view .chose-item .day-text {
  font-size: 18px;

  font-weight: 600;
  color: #342626;
  line-height: 28px;
}

.pay-inf-view .pay-chose-list-view .chose-item .mony-text {
  font-size: 22px;

  font-weight: 600;
  color: #f0bf3e;
  line-height: 28px;
  margin-top: 2px;
}

.pay-inf-view .pay-chose-list-view .chose-item .mony-text span {
  font-size: 14px;
}

.pay-inf-view .day-tips-view {
  width: 530px;
  height: 80px;
  background-color: #fff5f0;
  margin-left: 24px;
  border-radius: 8px;
  padding: 15px;
  margin-top: 30px;
}
.pay-inf-view .day-tips-view .tips-title-view {
  font-weight: 500;
  font-size: 18px;
  color: #f34b37;
  line-height: 30rpx;
}

.pay-inf-view .day-tips-view .days-num-view {
  font-weight: 300;
  font-size: 16px;
  color: #f34b37;
  line-height: 30rpx;
  margin-top: 10px;
}

.pay-inf-view .dialog-footer .el-button--primary {
  width: 300px;
  height: 60px;
  font-size: 20px;
}

.job-manage-page .el-dialog__wrapper .el-dialog {
  border-radius: 20px;
}
.job-manage-page .common-title-view {
  width: 1196px;
  position: relative;
}
.job-manage-page .common-title-view .right-add-btn {
  position: absolute;
  right: 0;
}

.job-manage-page .common-title-view .right-add-btn .el-button--primary {
  width: 86px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-manage-page .coupon-navbar-view {
  margin-top: 17px;
  border-bottom: 3px solid #eaecf5;
}
.refr-histoy-list-view {
  border-top: 2px solid #eaecf5;
  padding: 30px 60px;
}

.refr-histoy-list-view .refr-histoy-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
}

.refr-histoy-list-view .refr-histoy-list-item .tt1 {
  font-size: 20px;

  font-weight: 400;
  color: #222222;
  line-height: 28px;
}

.refr-histoy-list-view .refr-histoy-list-item .tt2 {
  font-size: 16px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 19px;
}
.refr-histoy-list-view .zw-text {
  width: 100%;
  text-align: center;
  font-size: 16px;

  font-weight: 400;
  color: #b1b1b1;
  line-height: 19px;
  margin-top: 24px;
}

.account-aout-view {
  padding: 30px 31px;
}

.account-aout-view .desptext {
  font-size: 20px;
}
.account-aout-view .desptext label {
  color: red;
}

.account-aout-view .chose-day-view {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.account-aout-view .chose-day-view .day-item {
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eaecf5;
  border-radius: 10px;
  font-size: 25px;
}
.account-aout-view .chose-day-view .day-item-sl {
  color: #fe6002;
  border: 1px solid #fe6002;
}

.account-aout-view .foot-btn {
  width: 100%;
  text-align: right;
  margin-top: 50px;
}
.job-manage-page .vipServe-content {
  border-top: 2px solid rgba(230, 230, 230, 0.5);
  padding: 50px 50px 80px;
}
</style>